import React from "react";

const NotFound = () => {
  return (
    <div
      className="text-gray-900 "
      style={{
        fontSize: 60,
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span>Not Found 404</span>
    </div>
  );
};

export default NotFound;
