import React from "react";
import bg from "../assets/imgs/bg.jpg";
import img from "../assets/imgs/bg2.jpg";
import map_img from "../assets/imgs/map.png";
import Footer from "../components/Footer/Footer";
import {
  ClockIcon,
  ServerIcon,
  ChartBarIcon,
  PhoneIcon,
  MailIcon,
  PlayIcon,
} from "@heroicons/react/solid";

const callsToAction = [
  {
    name: "Regarder la vidéo Demo",
    href: "https://youtu.be/bOGalrPUDAY",
    icon: PlayIcon,
  },
  {
    name: "Contacter le service commercial",
    href: "tel:+212703112627",
    icon: PhoneIcon,
  },
  {
    name: "Contacter le service commercial",
    href: "mailto:commercial@samablue.ma",
    icon: MailIcon,
  },
];

export default function Home() {
  return (
    <>
      <div className="relative bg-white md:h-[95vh]">
        <div className=" absolute bottom-8 md:bottom-4 w-screen justify-evenly md:justify-end right-0 ppx-5 py-5 z-10  flex sm:space-y-0 space-x-8 sm:space-x-10 px-20 sm:px-8  	">
          {callsToAction.map((item) => (
            <div
              key={item.name}
              className="flow-root opacity-70 hover:opacity-90 "
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={item.href}
                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:text-gray-700 bg-gray-100 hover:bg-blue-100"
              >
                <item.icon
                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3 hidden md:flex">{item.name}</span>
              </a>
            </div>
          ))}
        </div>
        <div className="absolute z-40 mt-28 md:mt-10 ">
          <h1 className="text-[36px] tracking-tight font-extrabold text-gray-900 md:text-[60px] pt-[40px] md:pt-40 text-center mb-10">
            <span
              className=" xl:inline text-white"
              style={{ textShadow: "2px 4px 3px rgba(0,0,0,0.3)" }}
            >
              La solution 360° pour{" "}
            </span>
            <span
              className=" text-[#318CE7] xl:inline"
              style={{ textShadow: "2px 4px 3px rgba(0,0,0,0.3)" }}
            >
              le stockage de votre céréale
            </span>
          </h1>
          <p
            className="mt-3 text-base text-gray-200 px-[10px] md:px-[300px] py-3 md:mt-5 md:text-[23px] text-center leading-6 md:leading-8"
            style={{ textShadow: "2px 4px 3px rgba(0,0,0,0.3)" }}
          >
            SAMABLUE possède une vaste expérience internationale dans la gestion
            de grands projets avec un portefeuille complet pour la manutention,
            le stockage et la transformation du grain. Toujours fidèle à notre
            proposition de valeur.
          </p>
          <div className="mt-5 sm:mt-8 flex justify-center items-start	gap-5">
            <div className="rounded-md shadow cursor-pointer">
              <a
                href="/Convoyeurs"
                style={{
                  boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                }}
                className="w-full flex items-center justify-center px-6 py-5 border border-transparent text-base font-medium rounded-md text-white bg-[#318CE7] hover:bg-[#318Cc9] md:py-4 md:text-lg md:c"
              >
                Commencer
              </a>
            </div>
            <div className="cursor-pointer">
              <a
                href="/About"
                style={{ boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                class="inline-flex justify-center items-center p-5 text-base font-medium text-gray-500 bg-gray-50 rounded-lg hover:text-gray-900 hover:bg-gray-100"
              >
                <span class="w-full">Plus sur nous</span>
                <svg
                  aria-hidden="true"
                  class="ml-3 w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
             
            </div>
          </div>
        </div>

        <div className="lg:absolute h-[95vh] w-full overflow-hidden">
          <img
            className="movement-img h-[100vh] w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={bg}
            alt="background silos"
          />
        </div>
      </div>

      {/*Section 2*/}

      <section class="text-gray-600 body-font ">
        <div class="container px-5 py-24 mx-auto ">
          <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center ">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-[#318CE7] font-bold">
              Pourquoi nous choisir ?
            </h1>
            <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">
              <span className="text-[#318CE7] font-semibold	">SAMA</span>
              <span className="text-[#E8BD5E] font-semibold	">BLUE </span>
              possède une vaste expérience internationale dans la gestion de
              grands projets avec un portefeuille complet pour la manutention.
            </p>
            <hr class="my-4 mx-auto w-48 h-1 bg-blue-200 rounded border-0 md:my-10 " />
          </div>

          <div class="flex flex-wrap -m-4">
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2 ">
                  Concepts intelligents d'installations sur mesure au profit du
                  client
                </h2>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="6" cy="6" r="3"></circle>
                    <circle cx="6" cy="18" r="3"></circle>
                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2 ">
                  Ingénierie de base et fine interne, y compris la conception
                  statique des structures en acier
                </h2>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2 ">
                  Conception industrielle du processus de fabrication
                </h2>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2 ">
                  Une attention particulière pour l'efficacité de fonctionnement
                  et l'économie d'énergie
                </h2>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2 ">
                  Ingénieries allemande et espagnol, fabriquée en Europe
                </h2>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="border border-gray-200 p-6 rounded-lg">
                <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>
                </div>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-2 ">
                  Entreprise familiale, hiérarchie courte, décisions rapides,
                  engagement fort
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 */}

      <section class="bg-white ">
        <div class="container px-6 py-10 mx-auto">
          <div class="lg:flex lg:items-center">
            <div class="w-full space-y-12 lg:w-1/2 ">
              <div>
                <div class="mt-2">
                  <span class="inline-block w-40 h-1 rounded-full bg-[#318CE7]"></span>
                  <span class="inline-block w-3 h-1 ml-1 rounded-full bg-[#318CE7]"></span>
                  <span class="inline-block w-1 h-1 ml-1 rounded-full bg-[#318CE7]"></span>
                </div>
              </div>

              <div class="md:flex md:items-start md:-mx-4">
                <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>
                </span>

                <div class="mt-4 md:mx-4 md:mt-0">
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    INSTALLATION
                  </h1>

                  <p class="mt-3 text-gray-500 ">
                    Les silos SAMABLUE sont conçus de manière modulaire, ce qui
                    facilite grandement leur assemblage. Grâce à la qualité des
                    matériaux utilisés pour leur fabrication et à une équipe de
                    professionnels très expérimentés, SAMABLUE conçoit, produit
                    et installe des solutions sur mesure partout dans le monde.
                    Ce sont des structures très résistantes dans tous les types
                    d’environnements, garantissant la conservation de tous les
                    types de céréales pendant de longues périodes.
                  </p>
                </div>
              </div>

              <div class="md:flex md:items-start md:-mx-4">
                <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                    />
                  </svg>
                </span>

                <div class="mt-4 md:mx-4 md:mt-0">
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    PRODUCTION
                  </h1>

                  <p class="mt-3 text-gray-500 ">
                    Nos silos sont produits avec une technologie de pointe et
                    nous n’hésitons pas à opter pour une qualité supérieure dans
                    le choix des matières premières. Les aciers que nous
                    utilisons comme base correspondent à la qualité S-350GD +
                    Z600, offrant une plus grande résistance à la corrosion, et
                    peuvent même être fournis peints, selon la demande du
                    client. Les silos en tôle galvanisée sont aujourd’hui la
                    meilleure alternative pour le stockage des céréales grâce à
                    leur polyvalence, leur facilité de montage, leur
                    manipulation hygiénique et leur faible coût de stockage.
                  </p>
                </div>
              </div>

              <div class="md:flex md:items-start md:-mx-4">
                <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                    />
                  </svg>
                </span>

                <div class="mt-4 md:mx-4 md:mt-0">
                  <h1 class="text-2xl font-semibold text-gray-700 capitalize ">
                    CONCEPTION
                  </h1>

                  <p class="mt-3 text-gray-500 ">
                    Notre service de conception dispose d’une équipe
                    d’ingénieurs spécialisés, qui travaillent au cas par cas et
                    aux côtés du client, lui prêtent un service strictement
                    personnalisé et supervisent le développement du projet à
                    tout moment, de sa création à son exécution et à son
                    installation. Toutes les structures conçues par notre équipe
                    sont calculées selon les normes américaines ASAE/ANSI ou
                    européennes Eurocodes et pour supporter le stress d’une
                    utilisation continue, les températures extrêmes, les chutes
                    de neige et les vents violents ainsi que les charges
                    sismiques.
                  </p>
                </div>
              </div>
            </div>

            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
              <img
                class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full"
                src={img}
                alt="silos"
              />
            </div>
          </div>

          <hr class="border-gray-200 my-12 " />

        
        </div>
      </section>
      {/* section 3 */}
      <section>
        <div className="bg-gray-50 ">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">EXPANSION GLOBALE DE</span>
              <span className="block text-[#318CE7]">SAMABLUE</span>
              <div class="mt-2">
                <span class="inline-block w-40 h-1 rounded-full bg-[#318CE7]"></span>
                <span class="inline-block w-20 h-1 ml-1 rounded-full bg-[#318CE7]"></span>
                <span class="inline-block w-1 h-1 ml-1 rounded-full bg-[#318CE7]"></span>
                {/* <span class="inline-block w-1 h-1 ml-1 rounded-full bg-[#318CE7]"></span> */}
              </div>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 relative">
              <img src={map_img} alt="map afrique" />
              <div className="w-3 h-3 bg-[#318CE7] absolute top-[70px] left-[150px] md:top-[120px] md:left-[250px] rounded-full border-2 border-white hover:border-blue-900" />
              <div className="w-16 h-5 bg-[#318CE7] absolute top-[45px] left-[125px] md:top-[95px] md:left-[225px] rounded border-2 border-white text-white text-xs flex justify-center">
                Afrique
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section 4 */}

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Statistiques de l'année 2022
            </h1>
          </div>
          <div class="flex flex-wrap -m-4 text-center">
            <div class="p-4 md:w-1/6 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-[#318CE7] w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path d="M8 17l4 4 4-4m-4-5v9"></path>
                  <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
                </svg>
                <h2 class="title-font font-medium text-3xl text-gray-900">
                  50
                </h2>
                <p class="leading-relaxed">TRAVAILLEURS</p>
              </div>
            </div>
            <div class="p-4 md:w-1/6 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-[#318CE7] w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                </svg>
                <h2 class="title-font font-medium text-3xl text-gray-900">
                  4.215K
                </h2>
                <p class="leading-relaxed">CLIENTS SATISFAITS</p>
              </div>
            </div>
            <div class="p-4 md:w-1/6 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg ">
                <div className="flex justify-center">
                  <ClockIcon className="text-[#318CE7] h-14 w-14 pb-1" />
                </div>
                <h2 class="title-font font-medium text-3xl text-gray-900">
                  51 ANS
                </h2>
                <p class="leading-relaxed">DES ANNÉES D’EXPÉRIENCE</p>
              </div>
            </div>
            <div class="p-4 md:w-1/6 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="text-[#318CE7] w-12 h-12 mb-3 inline-block"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
                <h2 class="title-font font-medium text-3xl text-gray-900">
                  45
                </h2>
                <p class="leading-relaxed">PAYS</p>
              </div>
            </div>
            <div class="p-4 md:w-1/6 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg ">
                <div className="flex justify-center">
                  <ChartBarIcon className="text-[#318CE7] h-14 w-14 pb-1" />
                </div>
                <h2 class="title-font font-medium text-3xl text-gray-900">
                  10.2K
                </h2>
                <p class="leading-relaxed">TONNES D'ACIER</p>
              </div>
            </div>
            <div class="p-4 md:w-1/6 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg ">
                <div className="flex justify-center">
                  <ServerIcon className="text-[#318CE7] h-14 w-14 pb-1" />
                </div>
                <h2 class="title-font font-medium text-3xl text-gray-900">
                  480
                </h2>
                <p class="leading-relaxed">INSTALLATIONS</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
