import React from "react";
import img1 from "../assets/imgs/gallery/img_1.jpg";
import img2 from "../assets/imgs/gallery/img_2.jpg";
import img3 from "../assets/imgs/gallery/img_3.jpg";
import img4 from "../assets/imgs/gallery/img_4.jpg";
import img5 from "../assets/imgs/gallery/img_5.jpg";
import img6 from "../assets/imgs/gallery/img_6.jpg";
import img7 from "../assets/imgs/gallery/img_7.jpg";
import img8 from "../assets/imgs/gallery/img_8.jpg";
import img9 from "../assets/imgs/gallery/img_9.jpg";
import img10 from "../assets/imgs/gallery/img_10.jpg";
import img11 from "../assets/imgs/gallery/img_11.jpg";
import img12 from "../assets/imgs/gallery/img_12.jpg";
import img13 from "../assets/imgs/gallery/img_13.jpg";
import img14 from "../assets/imgs/gallery/img_14.jpg";
import img15 from "../assets/imgs/gallery/img_15.jpg";
import img16 from "../assets/imgs/gallery/img_16.jpg";
import img17 from "../assets/imgs/gallery/img_17.jpg";
import img18 from "../assets/imgs/gallery/img_18.jpg";
import Footer from "../components/Footer/Footer";

const Gallery = () => {
  return (
    <>
      <section class="text-gray-600 body-font pt-20">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          {/* <div class="flex w-full mb-20 flex-wrap">
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">
              Master Cleanse Reliac Heirloom
            </h1>
            <p class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">
              Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
              gentrify, subway tile poke farm-to-table. Franzen you probably
              haven't heard of them man bun deep jianbing selfies heirloom.
            </p>
          </div> */}
          <div class="flex flex-wrap md:-m-2 -m-1">
            <div class="flex flex-wrap w-1/2">
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img1}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img2}
                />
              </div>
              <div class="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center block"
                  src={img3}
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div class="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center block"
                  src={img4}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img5}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img6}
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap md:-m-2 -m-1 pt-[15px]">
            <div class="flex flex-wrap w-1/2">
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img7}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img8}
                />
              </div>
              <div class="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center block"
                  src={img9}
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div class="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center block"
                  src={img10}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img11}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img12}
                />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap md:-m-2 -m-1 pt-[15px]">
            <div class="flex flex-wrap w-1/2">
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img13}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img14}
                />
              </div>
              <div class="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center block"
                  src={img15}
                />
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <div class="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center block"
                  src={img16}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img17}
                />
              </div>
              <div class="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  class="w-full object-cover h-full object-center block"
                  src={img18}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Gallery;
